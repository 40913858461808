.modal-export {
    .modal-export-tab-bar {
        display: flex;
        justify-content: space-evenly;

        button {
            flex-grow: 1;
            border-radius: 0px;
            outline: none;
            padding: 12px 16px;

            &.selected {
                font-weight: bold;
                font-size: 1.2em;
                background: #444;
            }
        }
    }

    .modal-export-content {
        animation: show-modal-export-content 0.4s;

        &:empty {
            display: none;
        }

        p {
            margin: revert;
        }

        textarea {
            width: 100%;
            height: 60vh;
            max-height: 90vh;
            font-size: 0.8em;
            padding: 0px;
            border: 0px;
        }
    }
}
