
@keyframes add-item {
  0% {
    opacity: 0;
    transform: scaleY(0);
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
  }
}

@keyframes show-section {
  0% {
    opacity: 0;
    transform: scale(0);
    box-shadow: 0px 0px 10px 10px #444;
  }
  80% {
    opacity: 1;
    transform: scale(1);
    box-shadow: 0px 0px 30px 30px #444;
  }
  100% {
    box-shadow: 0px 0px 10px 10px #444;
  }
}

@keyframes upvote {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  80% {
    opacity: 1;
    transform: translateY(-4px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes downvote {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  80% {
    opacity: 1;
    transform: translateY(4px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes show-title {
  0% {
    opacity: 0;
    margin: 0px;
    max-height: 0px;
    box-shadow: 0px 0px 0px 0px black;
    background: black;
  }
  100% {
    opacity: 1;
    margin: 8px auto 34px auto;
    max-height: none;
    box-shadow: 0px 0px 5px 5px #444;
    background: #444;
  }
}

@keyframes show-error {
  0% {
    opacity: 0;
    margin: 0px;
    max-height: 0px;
    box-shadow: 0px 0px 0px 0px black;
    background: black;
  }
  100% {
    opacity: 1;
    margin: 10px auto 40px auto;
    max-height: none;
    box-shadow: 0px 0px 10px 10px red;
    background: red;
  }
}

@keyframes show-vote-button {
  0% {
    opacity: 0;
    transform: scale(0.1);
  }
  100% {
    opacity: 0.5;
    transform: scale(1);
  }
}

@keyframes show-nav-button {
  0% {
    opacity: 0;
    transform: translateY(-50px) scale(0.6);
    box-shadow: 0px 0px 5px 5px #333;
  }
  80% {
    opacity: 1;
    box-shadow: 0px 0px 10px 10px #333;
    transform: translateY(0px) scale(1);
  }
  100% {
    box-shadow: 0px 0px 10px 2px #333;
  }
}

@keyframes show-overlay {
  0% {
   opacity: 0;
  }
  100% {
   opacity: 1;
  }
}

@keyframes show-modal {
  0% {
    transform: scale(0.1);
  }
  100% {
    transform: scale(1.0);
  }
}

@keyframes show-modal-export-content {
  0% {
    opacity: 0;
    transform: scaleY(0);
  }
  100% {
    opacity: 1;
    transform: scaleY(1.0);
  }
}

@keyframes show-markdown-formatting-hint {
	0% {
		opacity: 0;
		overflow-y: hidden;
		max-height: 0px;
	}
	10% {
		opacity: 0;
	}
	99% {
		opacity: 0.72;
		overflow-y: visible;
		max-height: 25vh;
	}
	100% {
		max-height: 100vh;
	}
}


@keyframes show-toast {
	0% {
		opacity: 0;
		transform: translateX(100px) scale(0.8);
	}
	10% {
		opacity: 0;
	}
	99% {
		opacity: 1;
	}
	100% {
		transform: translateX(0px) scale(1.0);
	}
}

@keyframes show-participant {
	0% {
		transform: translateY(35px);
	}
	10% {
		transform: translateY(13px);
	}
	40% {
		transform: translateY(13px) scale(1);
	}
	50% {
		transform: translateY(-7px) scale(1.6);
	}
	54% {
		transform: translateY(-7px) scale(1.6) rotate(5deg) translateX(5px);
	}
	58% {
		transform: translateY(-7px) scale(1.6) rotate(-5deg) translateX(-3px);
	}
	62% {
		transform: translateY(-7px) scale(1.6) rotate(5deg) translateX(3px);
	}
	66% {
		transform: translateY(-7px) scale(1.6) rotate(-5deg) translateX(-3px);
	}
	70% {
		transform: translateY(-7px) scale(1.6) rotate(5deg) translateX(3px);
	}
	74% {
		transform: translateY(-7px) scale(1.6) rotate(-5deg) translateX(-3px);
	}
	78% {
		transform: translateY(-7px) scale(1.6) rotate(5deg) translateX(3px);
	}
	81% {
		transform: translateY(-7px) scale(1.5) rotate(0deg) translateX(0px);
	}
	100% {
		transform: translateY(0px) scale(1.0);
	}
}

@keyframes show-new-comment-box {
	0% {
		opacity: 0;
		overflow: hidden;
		max-height: 0px;
	}
	99% {
		opacity: 1;
		overflow: hidden;
		max-height: 2em;
	}
	100% {
		overflow: unset;
		max-height: unset;
	}
}

@keyframes new-item-participant-typing {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0.9;
	}
	100% {
		opacity: 1;
	}
}
