$layout-break-point: 620px;
$layout-break-point-2: 400px;

#home-page {
	margin: 0px #{"max(4px, 1%)"};
	display: flex;
	flex-direction: row;

	@media(max-width: $layout-break-point) {
		display: block;
	}

	section {
		&.create-new-retro {
			flex: 2;

			#create-new-retro {
				background: linear-gradient(#333, #111);
				border-radius: 5px;
				padding: 12px;
				margin-right: 24px;
				transition: all 0.4s;

				@media(max-width: $layout-break-point) {
					margin-right: 0px;
				}

				&.loading {
					opacity: 0.9;
					pointer-events: none;
				}

				> div {
					> div {
						margin-bottom: 12px;

						&.title {
							input {
								display: block;
								font-size: 1.4em;
								background: #111;
								border: 1px solid #444;
								padding: 2px 4px;
								border-radius: 5px;
								width: calc(100% - 2*8px);
								color: white;
							}
						}

						&.vote-mode {
							.options {
								display: flex;
								flex-direction: row;

								@media(max-width: $layout-break-point-2) {
									flex-direction: column;
								}

								label {
									flex: 1;
									background: linear-gradient(#333, #111);
									border-radius: 5px;
									text-align: center;
									padding: 12px 16px;
									margin: 0px 8px;
									cursor: pointer;
									font-size: 1.2em;

									@media(max-width: $layout-break-point-2) {
										margin-left: 0px;
										margin-right: 0px;
									}

									&:first-child {
										margin-left: 0px;
									}
									&:last-child {
										margin-right: 0px;
									}
								}
							}
						}

						&.create {
							text-align: center;
							margin-top: 16px;

							button {
								background: linear-gradient(#333, #111);
								color: white;
								border: 1px solid #444;
								border-radius: 5px;
								font-size: 1.4em;
								padding: 8px 12px;
								box-shadow: 0px 0px 4px 0px #AAA;
								cursor: pointer;
								transition: all 0.2s;

								&:hover {
									box-shadow: 0px 0px 8px 0px #AAA;
								}
							}
						}
					}
				}
			}
		}

		&.previous-retros {
			flex: 1;

			a {
				text-decoration: none;
				color: inherit;
			}

			ul.previous-retros {
				padding: 0px;

				.previous-retro {
					list-style-type: none;
					background: linear-gradient(#333, #111);
					border-radius: 5px;
					margin-bottom: 8px;
					cursor: pointer;

					.name {
						text-align: center;
						display: block;
						padding: 4px 8px;
						border-bottom: 1px solid rgba(255, 255, 255, 0.2);
						font-size: 0.8em;

						&.title {
							font-size: 1.2em;
						}
					}

					li {
						font-size: 1.6em;
						font-weight: 300;
					}

					> ul {
						display: flex;
						flex-direction: row;
						padding: 0px;

						li {
							list-style-type: none;
							flex: 1;
							text-align: center;
							padding: 8px;
						}
					}
				}
			}
		}
	}

	.error {
		color: red;
		text-shadow: 0px 0px 2px white;
	}
}
