.modal-set-vote-mode {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;

    button {
        margin: 10px;
        cursor: pointer;

        &:hover {
            box-shadow: 0px 0px 10px 2px #AAA;
        }
    }
}