#stats-n-participants {
	position: fixed;
	bottom: -5px;
	left: 0px;
	right: 0px;
	text-align: center;

	> span {
		.avatar {
			cursor: default;
			font-size: 28px;
			display: inline-block;
			animation: show-participant 3.6s;
			animation-iteration-count: 1;

			&:hover {
				margin-left: 8px;
				margin-right: 8px;
				transform: translateY(-10px) scale(1.8);
			}

			&.typing {
				margin-left: 4px;
				margin-right: 4px;
				transform: translateY(-4px) scale(1.4);
			}
		}

		&.latency {
			display: inline-block;
			font-size: 0.8em;
			border: 1px solid lime;
			background: green;
			color: white;
			border-radius: 3px;
			margin: 3px;

			&.bad {
				border-color: red;
				background: maroon;
				color: white;
			}
		}
	}
}
