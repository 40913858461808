@import url('https://fonts.googleapis.com/css?family=Delius&display=swap');

html, body {
	background: black;
	color: white;
}

body * {
	transition: all 0.5s;
}

main {
	&.error {
		pointer-events: none;
	}

	&.theme-colourful {
		background: white;
		color: black;
		box-shadow: 0px 10px 100px 100px white;

		font-family: 'Comic Sans', 'Comic Sans MS', 'TSCu_Comic', 'Delius', sans-serif;
	}
}

.fork-me-on-github {
	@media (max-width: 860px) {
		display: none;
		position: fixed !important;
		top: unset !important;
		bottom: 0px !important;
		transform: rotate(90deg);

		svg {
			width: 40px;
			height: 40px;
		}
	}
}

nav {
	background: linear-gradient(#333, black);

	.error & {
		pointer-events: none;
		background: linear-gradient(red, black);
	}

	.theme-colourful & {
		background: linear-gradient(purple, white);
	}

	> ul {
		margin: 0px;
		padding: 16px 24px;
		list-style-type: none;

		@media (max-width: 860px) {
			padding: 1vh 2vw;

			display: grid;
			grid-template-columns: 30vw 30vw 30vw;
			grid-gap: 3vw;
		}

		@media (max-width: 530px) {
			display: grid;
			grid-template-columns: 45vw 45vw;
			grid-gap: 1vh 5vw;
		}

		@media (max-width: 360px) {
			display: grid;
			grid-template-columns: 96vw;
			grid-gap: 0px;
		}

		> li {
			display: inline-block;
			background: linear-gradient(#222, #111);
			box-shadow: 0px 0px 10px 2px #333;
			border-radius: 8px;
			padding: 8px 12px;
			color: #CCC;
			cursor: pointer;
			margin: 0px 4px;
			animation: show-nav-button 1.2s;

			.theme-colourful & {
				background: linear-gradient(#6cd4ff, #EEE);
				box-shadow: 0px 0px 10px 10px #6cd4ff;
				color: #444;
			}

			@media (max-width: 600px) {
				text-align: center;
				margin: 6px 0px;
			}

			@media (min-width: 900px) {
				&:first-child {
					margin-left: 0px;
				}
			}

			&:not(.stats):hover {
				box-shadow: 0px 0px 5px 5px #444;
				border-radius: 5px;
				color: white;
				background: linear-gradient(#222, black);

				.theme-colourful & {
					box-shadow: 0px 0px 12px 16px #6cd4ff;
					color: black;
					background: linear-gradient(#7ce4ff, white);
				}
			}
		}
	}
}

h1 {
	text-align: center;
	margin: 8px auto 34px auto;
	box-shadow: 0px 0px 5px 5px #444;
	padding: 8px 16px;
	background: #444;
	color: #EEE;
	overflow: hidden;
	animation: show-title 0.5s;
}

p#error {
	text-align: center;
	margin: 10px auto 40px auto;
	box-shadow: 0px 0px 10px 10px red;
	padding: 8px 16px;
	background: red;
	color: black;
	animation: show-error 0.5s;
}

article {
	display: grid;
	grid-template-columns: 30vw 30vw 30vw;
	grid-gap: 3vw;
	justify-content: center;
	margin: 20px;

	.error & {
		opacity: 0.8;
	}

	@media (max-width: 1400px) {
		grid-template-columns: 45vw 45vw;
	}

	@media (max-width: 900px) {
		grid-template-columns: 95vw;
	}

	> section {
		background: #222;
		box-shadow: 0px 0px 10px 10px #444;
		overflow: hidden;
		animation: show-section 1.2s ease;
		width: 100%;
		margin-bottom: 3vh;

		.theme-colourful & {
			&#good {
				background: darkgreen;
				box-shadow: 0px 0px 20px 20px lime;

				> h2 {
					text-shadow: 0px 0px 5px lime;
				}
			}

			&#bad {
				background: darkred;
				box-shadow: 0px 0px 20px 20px red;

				> h2 {
					text-shadow: 0px 0px 5px red;
				}
			}

			&#actions {
				background: darkblue;
				box-shadow: 0px 0px 20px 20px blue;

				> h2 {
					text-shadow: 0px 0px 5px blue;
				}
			}
		}

		> h2 {
			text-align: center;
			cursor: default;
			color: #EEE;

			.theme-colourful & {
				color: white;
			}
		}
	}

	ul.items {
		list-style-type: none;
		padding: 0px;

		li {
			&.newItem {
				.item-content-main {
					background: #333;
					box-shadow: 0px 0px 6px 6px #333;

					.theme-colourful & {
						background: rgba(0, 0, 0, 0.4);
						box-shadow: 0px 0px 6px 6px rgba(0, 0, 0, 0.4);
					}

					form {
						flex-grow: 1;

						input {
							width: 100%;
							background: #333;
							border: 0px;
							font-size: 1rem;
							color: white;
							outline: none; // Chrome focus outline
							box-shadow: none; // Firefox validation outline

							.theme-colourful & {
								background: transparent;
								color: white;
							}

							&::placeholder {
								color: #AAA;

								.theme-colourful & {
									color: #DDD;
								}
							}
						}

						input:focus + .markdown-formatting-hint {
							display: block;
						}

						.markdown-formatting-hint {
							display: none;
							font-size: 0.8em;
							opacity: 0.72;
							animation: show-markdown-formatting-hint 1s;
							margin-top: 6px;

							span {
								white-space: nowrap;
							}

							.bold {
								font-weight: bold;
							}

							.italic {
								font-style: italic;
							}

							.monospace {
								font-family: monospace;
							}

							.checkbox {
								input {
									display: inline;
									width: unset;
									margin: 0px;
									pointer-events: none;
								}
							}
						}
					}
				}

				&.participantTyping {
					animation: new-item-participant-typing 2s infinite;
				}
			}

			&.has-comments {
				.item-content-main {
					margin-bottom: 0px;
				}

				.comments {
					display: block;
				}
			}

			&:hover {
				.comments {
					display: block;

					.new-comment {
						display: flex !important;
						animation: show-new-comment-box 0.5s ease;
					}
				}
			}

			.comments {
				display: none;
				list-style-type: none;
				padding: 0px 0px 3px 0px;
				background: #333;
				box-shadow: 0px 0px 6px 4px #333;

				.comment {
					margin: 3px 3px 3px 2em;
					padding: 1px 0px;

					&::before {
						display: inline-block;
						content: '💬';
						opacity: 0.64;
						margin-right: 8px;
					}

					&.new-comment, &.new-comment.editing {
						display: none;
					}

					&.editing {
						display: flex;
						flex-direction: row;

						input {
							width: 100%;
							background: #333;
							border: 0px;
							font-size: 1rem;
							color: white;
							outline: none; // Chrome focus outline
							box-shadow: none; // Firefox validation outline

							.theme-colourful & {
								background: transparent;
								color: white;
							}

							&::placeholder {
								color: #AAA;

								.theme-colourful & {
									color: #DDD;
								}
							}
						}
					}
				}
			}

			.item-content-main {
				display: flex;
				background: #444;
				box-shadow: 0px 0px 6px 4px #444;
				padding: 8px 16px;
				margin: 12px 0px;
				animation: add-item 0.5s ease;

				.theme-colourful & {
					background: rgba(0, 0, 0, 0.2);
					box-shadow: 0px 0px 6px 6px rgba(0, 0, 0, 0.2);
					color: white;
				}

				&.editing {
					background: #333;
					box-shadow: 0px 0px 7px 7px #333;

					.theme-colourful & {
						background: rgba(0, 0, 0, 0.4);
						box-shadow: 0px 0px 7px 7px rgba(0, 0, 0, 0.4);
					}

					.content {
						.votes {
							margin-left: 4px;
						}
					}
				}

				.vote {
					display: inline-block;
					margin-right: 4px;
					white-space: nowrap;

					.already-voted {
						pointer-events: none;
						filter: saturate(0);
					}

					button {
						display: inline-block;
						opacity: 0.5;
						padding: 3px;
						text-decoration: none;
						transition: all 0.2s;
						border: none;
						outline: none;
						background: transparent;
						font-size: 1rem;
						cursor: pointer;
						animation: show-vote-button 0.4s;

						&:hover {
							opacity: 1;
							transform: scale(1.8);
						}
					}
				}

				.content {
					display: flex;
					overflow-wrap: anywhere;
					flex-grow: 1;
					align-items: center;

					> span {
						display: inline-block;
						vertical-align: middle;

						&:not(.votes):hover {
							padding-left: 5px;
							padding-right: 5px;
						}

						a {
							color: inherit;
							text-decoration: none;

							&:hover {
								text-decoration: underline;
							}
						}

						input[type=checkbox] {
							width: 1.4em;
							height: 1.4em;
							margin: 0px 0.4em 0px 0px;
							vertical-align: middle;

							&:checked {
								& + label {
									text-decoration: line-through;
									opacity: 0.64;
								}
							}
						}
					}

					form {
						flex-grow: 1;

						input {
							background: transparent;
							border: 0px;
							color: white;
							font-size: 1em;
							width: 100%;
							padding-left: 5px;
							padding-right: 5px;

							&:focus {
								outline: none;
							}
						}
					}

					.votes {
						margin-left: 4px;

						.upvote {
							display: inline-block;
							animation: upvote 0.4s;
							font-size: 1.1em;
						}

						.downvote {
							display: inline-block;
							animation: downvote 0.4s;
							font-size: 1.1em;
						}
					}
				}
			}
		}
	}
}

.emoji {
	// MacOS is once again the ugly duckling...
	font-family: 'apple color emoji', 'segoe ui emoji', 'noto color emoji', 'android emoji', 'emojisymbols', 'emojione mozilla', 'twemoji mozilla', 'segoe ui symbol';
}

@import '_AccessKeyInput';
@import '_Home';
@import '_Overlay';
@import '_Modal';
@import '_ShareFallback';
@import '_SetVoteMode';
@import '_SetTheme';
@import '_SetAccessKey';
@import '_Export';
@import '_Toast';
@import '_stats';
@import '_animation';
