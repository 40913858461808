.modal {
    background: linear-gradient(#333, #111);
    padding: 16px 12px;
    border-radius: 8px;
    border: 2px solid #333;
    max-width: 90vw;
    width: 540px;
    box-shadow: 0px 0px 200px 100px rgba(255, 255, 255, 0.1);
    position: relative;
    animation: show-modal 0.6s;

	.modal-content {
		overflow-x: visible;
		overflow-y: auto;
		max-height: 90vh;
	}

    .theme-colourful & {
        background: linear-gradient(yellow, orange);
    }

    p:first-child {
        margin-top: 0px;
    }

    p:last-child {
        margin-bottom: 0px;
        text-align: center;
    }

    input:not([type=radio]) {
        display: block;
        width: calc(100% - 2*4px - 2*1px);
        font-size: 1.2em;
        background: #222;
        border: 1px solid #444;
        border-radius: 3px;
        margin-top: 3px;
        color: #DDD;
        padding: 2px 4px;

        &[type=text] {
            outline: none;
        }
    }

    button {
        background: #333;
        color: white;
        border: 1px solid #444;
        font-size: 1.1em;
        padding: 4px 12px;
        border-radius: 3px;
    }

    .modal-close {
        position: absolute;
        top: -8px;
        right: -8px;
        background: red;
        border-radius: 100%;
        display: inline-block;
        width: 18px;
        height: 18px;
        text-align: center;
        box-shadow: 0px 0px 5px 2px rgba(255, 255, 255, 0.1);
        opacity: 0.8;
        cursor: pointer;
        overflow: hidden;

        &:hover {
            box-shadow: 0px 0px 5px 8px rgba(255, 255, 255, 0.2);
            opacity: 1;
        }
    }
}
