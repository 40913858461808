main#updater {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;

  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    width: 320px;
    height: 320px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: radial-gradient(#333, #222, black, black);

    #icon {
      font-size: 100px;
      text-shadow: 0px 0px 20px #555;
      animation-name: spin;
      animation-duration: 4.5s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }

    #message {
      font-size: 24px;
      font-weight: bold;
      color: #EEE;
      animation-name: shake;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes shake {
  0% {
    transform: translateX(-20px);
    opacity: 0.1;
  }
  25% {
    transform: translateX(0px);
    opacity: 1;
  }
  50% {
    transform: translateX(20px);
    opacity: 0.1;
  }
  75% {
    transform: translateX(0px);
    opacity: 1;
  }
  100% {
    transform: translateX(-20px);
    opacity: 0.1;
  }
}
