.overlay {
    display: flex;
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background: rgba(0, 0, 0, 0.74);
    justify-content: center;
    align-items: center;
    animation: show-overlay 0.6s;
}